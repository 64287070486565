/* .videoContainer {
  height: 500px;
  background-color: coral;
  display: flex;
} */

.videoElement {
  display: contents;
}

/* .local-render {
  height: 100px;
  width: 100px;
  float: right;
  display: flex;
} */

#pre-connect-deviceChoice-video {
  width: 80%;
  border-radius: 25px;
}

.confirmationMessage {
  font-size: 0.8em;
  margin-left: 0.5em;
}

.WaitingRoomImage {
  padding-left: 40%;
  margin-top: 10em;
}

.service-message h2 {
  font-size: medium;
}

@media screen and (min-width: 768px) {
  .service-message {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    background-color: lightgray;
    margin: 0.5em;
    padding: 0.5em;
  }
}

.participant-video-container {
  display: flex;
  flex-grow: 1;

  height: 100%;
}

.ChatModal {
  max-width: calc(80%);
  max-height: calc(100% - 30vh);
  width: calc(50%);
  height: 100%;
}

.ChatMessage {
  background-color: lightgray;
  display: table;
  border-radius: 0.4em;
  margin: 5px;
  padding: 10px;
  margin-top: 10px;
  max-width: 50%;
}
.ChatMessageContent {
  word-break: break-all;
}

.SelfMessage {
  background-color: lightblue;
  margin-left: auto;
}

.ChatHeader {
  font-size: x-small;
  font-weight: bold;
  font-variant: small-caps;
  margin-bottom: 5px;
}

.ChatMessageTime {
  margin-left: 2px;
}

.ChatAndSendContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: darkgrey;
}

.ChatContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 50px;
  overflow-y: auto;
  -webkit-overflow-y: scroll;
  -webkit-overflow-scrolling: touch; 

}
.ChatSendBox {
  margin-top: 10px;
  background-color: darkgray;
  min-height: 50px;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding-bottom: 5px
}

.ChatSendButton,
.ChatDisconnectButton {
  width: 20%;
  border-radius: 0.4em;
  margin-right: 10px;
}

.ChatCloseButton {
width: 20%;
border-radius: 0.4em;
margin-right: 10px;
}

.ChatSendTextInput {
  width: 80%;
  margin-left: 10px;
  margin-right: 10px;
  height: 30px;
  border-radius: 0.4em;
  padding-bottom: 2px;
}

.NoChatsYet {
  font-size: medium;
  padding-left: 40%;
}


 .ms-Modal-scrollableContent
{
  overflow-y: none !important;
  margin-bottom: 55px;
} 

.waitingRoomButtons {
  position: absolute;
  bottom: 20px;
  left: 45%;
}

.carouselImage {
  margin-left: auto;
  margin-right: auto;
  height: auto;
}

.carouselContainer {
  height: 70%;
  width: 100%;
}

.DisconnectOptionButton {
    width: 30%;
    border-radius: 0.4em;
    margin-right: 5px;
    margin-left: 5px;
    vertical-align: middle;
    height: auto;
    min-height: 50px;
    padding-bottom: 5px;
}

.DisconnectButtons {
 
 margin-top: 30px;
 
}

.DisconnectContainer {
  margin: 0.3em;
}

@media (max-width : 460px) {
  .ChatSendBox {
    margin-top: 10px;
    background-color: darkgray;
    min-height: 95px;
    align-items: center;
    display: block;
    position: absolute;
    bottom: 0px;
    width: 100%;
  }

  .ChatSendTextInput {  
    width: 97%;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 1px;
    min-height: 30px;
    border-radius: 0.4em;
    align-items: center;
  }
  .ChatSendButton {
    width: 40%;
    border-radius: 0.4em;
    margin-left: 5px;
    margin-right: 5px;
    float: right;
  }
  .ChatCloseButton {
    width: 40%;
    border-radius: 0.4em;
    margin-left: 5px;
    margin-right: 5px;
    float: left;
  }
}

.offline-background {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: Arial, sans-serif;
  text-align: center;

  .wrapper {
    text-align: center;
    background-color: #234964;
    border-radius: 1em;

  }

  h1 {
    font-size: 50px;
    letter-spacing: 5px;
    text-shadow: 0 0 15px white;
  }

  h4 {
    font-size: 16px;
    font-weight: 300;
    margin: 1em;
  }

  .logo {
    margin-bottom: 1em;
    width: 15em;
    background-color: white;
    padding: .5em;
    margin-top:.5em;
    display: block;
    
  }
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 99%; /* Adjust as needed */
    margin-bottom: .5em;

}

}
