*,
*:before,
*:after {
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
dl,
dd,
ol,
ul,
form,
fieldset,
legend,
table,
th,
td,
caption,
hr {
  margin: 0;
  padding: 0;
}
abbr[title],
dfn[title] {
  cursor: help;
}
a,
u,
ins {
  text-decoration: none;
}
ins {
  border-bottom: 1px solid;
}
img {
  font-style: italic;
  display: inline-block;
  max-width: 100%;
}
label,
input,
textarea,
button,
select,
option {
  cursor: pointer;
}
.text-input:active,
.text-input:focus,
textarea:active,
textarea:focus {
  cursor: text;
  outline: none;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden] {
  display: none;
}
html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  margin: 0;
}
a:focus {
  outline: thin dotted;
}
a:active,
a:hover {
  outline: none;
}
h1 {
  font-size: 2em;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: bold;
}
dfn {
  font-style: italic;
}
mark {
  background: #ff0;
  color: #000;
}
code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}
q {
  quotes: "\201C""\201D""\2018""\2019";
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 0;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  border: 0;
  padding: 0;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}
button,
input {
  line-height: normal;
}
button,
html input[type="primary-btn"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
input[disabled] {
  cursor: default;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
@-webkit-keyframes animateBubble {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  25% {
    -webkit-transform: translate3d(4%, 2%, 0);
    transform: translate3d(4%, 2%, 0);
  }
  50% {
    -webkit-transform: translate3d(2%, 0, 0);
    transform: translate3d(2%, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-4%, -1%, 0);
    transform: translate3d(-4%, -1%, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes animateBubble {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  25% {
    -webkit-transform: translate3d(4%, 2%, 0);
    transform: translate3d(4%, 2%, 0);
  }
  50% {
    -webkit-transform: translate3d(2%, 0, 0);
    transform: translate3d(2%, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-4%, -1%, 0);
    transform: translate3d(-4%, -1%, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes animateBubbleSml {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  25% {
    -webkit-transform: translate3d(15%, 7%, 0);
    transform: translate3d(15%, 7%, 0);
  }
  50% {
    -webkit-transform: translate3d(7%, 0, 0);
    transform: translate3d(7%, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10%, -4%, 0);
    transform: translate3d(-10%, -4%, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes animateBubbleSml {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  25% {
    -webkit-transform: translate3d(15%, 7%, 0);
    transform: translate3d(15%, 7%, 0);
  }
  50% {
    -webkit-transform: translate3d(7%, 0, 0);
    transform: translate3d(7%, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10%, -4%, 0);
    transform: translate3d(-10%, -4%, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
html,
body {
  background-color: white;
  color: #626262;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
  width: 100%;
}
html.nav-open,
body.nav-open {
  overflow: hidden;
}
html.scroll,
body.scroll {
  height: auto;
}
@media screen and (min-width: 768px) {
  html,
  body {
    height: 100%;
  }
}
body {
  background-position: right top;
  background-repeat: no-repeat;
}
@media screen and (min-width: 992px) {
  body {
    background-image: url("img/page-bg.png");
  }
}
@-moz-document url-prefix() {
  .bg .bubble {
    animation: none !important;
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
li {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  margin: 0;
  padding: 0;
}
h1 {
  font-size: 2.25em;
  line-height: 2.75rem;
}
h2 {
  font-size: 1.75em;
  line-height: 2.125rem;
}
h4 {
  font-size: 1.125em;
}
p {
  font-size: 1em;
  line-height: 1.375rem;
}
p.label {
  color: #84cff0;
}
b,
strong {
  font-weight: 400;
}
/* @font-face {
  font-family: "moc-icons";
  src: url("fonts/icons/moc-icons.eot?cognhh");
  src: url("fonts/icons/moc-icons.eot?cognhh#iefix") format("embedded-opentype"),
    url("fonts/icons/moc-icons.woff2?cognhh") format("woff2"),
    url("fonts/icons/moc-icons.ttf?cognhh") format("truetype"),
    url("fonts/icons/moc-icons.woff?cognhh") format("woff"),
    url("fonts/icons/moc-icons.svg?cognhh#moc-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}
i {
  font-family: "moc-icons" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-options:before {
  content: "\e912";
}
.icon-cross:before {
  content: "\e911";
}
.icon-check:before {
  content: "\e910";
}
.icon-user:before {
  content: "\e90b";
}
.icon-info:before {
  content: "\e90c";
}
.icon-help:before {
  content: "\e90a";
}
.icon-chevron-up:before {
  content: "\e905";
}
.icon-chevron-right:before {
  content: "\e906";
}
.icon-chevron-left:before {
  content: "\e907";
}
.icon-chevron-down:before {
  content: "\e908";
}
.icon-mic:before {
  content: "\e90d";
}
.icon-speakers:before {
  content: "\e90e";
}
.icon-webcam:before {
  content: "\e90f";
}
.icon-phone-call:before {
  content: "\e909";
}
.icon-arrow-right:before {
  content: "\e901";
}
.icon-desktop:before {
  content: "\e902";
}
.icon-phone:before {
  content: "\e903";
}
.icon-refresh:before {
  content: "\e904";
}
.icon-windows:before {
  content: "\e900";
} */
main {
  /* overflow: hidden; */
  position: relative;
  width: 100%;
}
.scroll main {
  height: auto;
  min-height: calc(100vh - 4.375em);
}
main.wb {
  background-color: white;
}
@media screen and (min-width: 768px) {
  main {
    height: 100%;
  }
}
.bg {
  height: 100vh;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 0;
}
section {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;
  z-index: 1;
}
section.a-top {
  align-items: flex-start;
  padding-top: 9em;
}
@media screen and (min-width: 768px) {
  section.a-top {
    padding-top: 12em;
  }
}
.scroll section {
  height: auto;
}
@media screen and (min-width: 768px), screen and (min-height: 850px) {
  section {
    height: 100%;
  }
}
.container {
  margin-left: auto;
  margin-right: auto;
  /* max-width: 100.875em; */
  padding: 0 1em;
  width: 100%;
}
.container--sml {
  max-width: 70.125em;
}
.col {
  position: relative;
  width: 100%;
  flex: none;
}
@media screen and (min-width: 768px) {
  .col {
    padding: 0;
  }
  .col--15 {
    width: 15%;
  }
  .col--15.push {
    left: 15%;
  }
  .col--20 {
    width: 20%;
  }
  .col--20.push {
    left: 20%;
  }
  .col--25 {
    width: 25%;
  }
  .col--25.push {
    left: 25%;
  }
  .col--33 {
    width: 33.33333%;
  }
  .col--33.push {
    left: 33%;
  }
  .col--35 {
    width: 35%;
  }
  .col--35.push {
    left: 35%;
  }
  .col--40 {
    width: 40%;
  }
  .col--40.push {
    left: 40%;
  }
  .col--50 {
    width: 50%;
  }
  .col--50.push {
    left: 50%;
  }
  .col--60 {
    width: 60%;
  }
  .col--60.push {
    left: 60%;
  }
  .col--65 {
    width: 65%;
  }
  .col--65.push {
    left: 65%;
  }
  .col--66 {
    width: 66.66667%;
  }
  .col--66.push {
    left: 66%;
  }
  .col--75 {
    width: 75%;
  }
  .col--75.push {
    left: 75%;
  }
  .col--80 {
    width: 80%;
  }
  .col--80.push {
    left: 80%;
  }
}
@media screen and (min-width: 992px) {
  .col--md-15 {
    width: 15%;
  }
  .col--md-20 {
    width: 20%;
  }
  .col--md-25 {
    width: 25%;
  }
  .col--md-33 {
    width: 33.33333%;
  }
  .col--md-35 {
    width: 35%;
  }
  .col--md-40 {
    width: 40%;
  }
  .col--md-50 {
    width: 50%;
  }
  .col--md-60 {
    width: 60%;
  }
  .col--md-65 {
    width: 65%;
  }
  .col--md-66 {
    width: 66.66667%;
  }
  .col--md-75 {
    width: 75%;
  }
  .col--md-80 {
    width: 80%;
  }
}
@media screen and (min-width: 1199px) {
  .col--lg-15 {
    width: 15%;
  }
  .col--lg-20 {
    width: 20%;
  }
  .col--lg-25 {
    width: 25%;
  }
  .col--lg-33 {
    width: 33.33333%;
  }
  .col--lg-35 {
    width: 35%;
  }
  .col--lg-40 {
    width: 40%;
  }
  .col--lg-50 {
    width: 50%;
  }
  .col--lg-60 {
    width: 60%;
  }
  .col--lg-65 {
    width: 65%;
  }
  .col--lg-66 {
    width: 66.66667%;
  }
  .col--lg-75 {
    width: 75%;
  }
  .col--lg-80 {
    width: 80%;
  }
}
.btn {
  background-color: #0082f9;
  border: 1px solid #0082f9;
  border-radius: 1.25rem;
  color: white;
  display: inline-block;
  font-size: 0.875em;
  font-weight: 300;
  height: 2.5rem;
  line-height: 2.5em;
  outline: 0;
  padding: 0 2.2rem;
  position: relative;
  transition: background-color 0.4s ease, border-color 0.4s ease,
    color 0.4s ease;
  width: 100%;
}
.btn i {
  font-size: 1.3em;
  left: 2.2rem;
  pointer-events: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media screen and (min-width: 576px) {
  .btn--lg {
    border-radius: 1.875rem;
    font-size: 1em;
    height: 3.75rem;
    line-height: 3.75em;
    min-width: 18.75rem;
  }
}
.btn:hover {
  background-color: #003f7a;
  border-color: #003f7a;
}
.btn--secondary {
  background-color: white;
  color: #0082f9;
}
.btn--secondary:hover {
  background-color: white;
  border-color: #84cff0;
  color: #84cff0;
}
.btn--green {
  background-color: #7aea77;
  border-color: #7aea77;
  box-shadow: 0 2px 6px rgba(34, 195, 30, 0.4);
  margin-top: 3em;
}
.btn--green:hover {
  background-color: #22c31e;
  border-color: #22c31e;
}
.btn--grey,
.btn--grey:hover {
  background-color: lightgray;
  border-color: lightgray;
  box-shadow: 0 2px 6px rgba(81, 82, 81, 0.4);
  margin-top: 3em;
}
@media screen and (min-width: 768px) {
  .btn {
    width: auto;
  }
}
form fieldset {
  border: none;
  margin: 0;
  padding: 0;
}
form input[type="text"],
form input[type="email"],
form input[type="tel"],
form input[type="password"] {
  background: white;
  border: none;
  border-bottom: 1px solid #c1c7ce;
  color: #1c3e56;
  font-size: 16px;
  font-weight: 300;
  height: 3.4375em;
  outline: 0;
  transition: border-color 0.2s ease;
  width: 100%;
}
form input[type="text"] + label,
form input[type="email"] + label,
form input[type="tel"] + label,
form input[type="password"] + label {
  color: #84cff0;
  font-size: 1em;
  font-weight: 300;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 1.125rem;
  -webkit-transform-origin: left;
  transform-origin: left;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
}
@media screen and (min-width: 768px) {
  form input[type="text"] + label,
  form input[type="email"] + label,
  form input[type="tel"] + label,
  form input[type="password"] + label {
    font-size: 1.125em;
  }
}
form input[type="text"].in,
form input[type="text"]:focus,
form input[type="email"].in,
form input[type="email"]:focus,
form input[type="tel"].in,
form input[type="tel"]:focus,
form input[type="password"].in,
form input[type="password"]:focus {
  border-color: #84cff0;
}
form input[type="text"].in + label,
form input[type="text"]:focus + label,
form input[type="email"].in + label,
form input[type="email"]:focus + label,
form input[type="tel"].in + label,
form input[type="tel"]:focus + label,
form input[type="password"].in + label,
form input[type="password"]:focus + label {
  -webkit-transform: translateY(-2rem) scale(0.8);
  transform: translateY(-2rem) scale(0.8);
}
@media screen and (min-width: 768px) {
  form input[type="text"],
  form input[type="email"],
  form input[type="tel"],
  form input[type="password"] {
    font-size: 1.125em;
  }
}
form .hint {
  color: #c1c7ce;
  font-size: 0.8125em;
  padding: 0.75rem 0 0;
}
form .input {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
}
form .input .field-icon {
  display: none;
  position: relative;
  width: 2.1875em;
}
form .input .field-icon span {
  background: #84cff0;
  border-radius: 50%;
  display: block;
  height: 1.375em;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 1.375em;
}
form .input .field-icon-small span i {
  /* bottom: 0; */
  color: #eef4f8;
  display: block;
  font-size: 1.4em;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

form .input .field-icon span i {
  /* bottom: 0; */
  color: #eef4f8;
  display: block;
  font-size: 1em;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

form .input .field-icon span i.icon-user {
  font-size: 1em;
  -webkit-transform: translate(-50%, -70%);
  transform: translate(-50%, -70%);
}
@media screen and (min-width: 768px) {
  form .input .field-icon {
    display: block;
  }
}
form .input input {
  flex-grow: 2;
  width: auto;
}
@media screen and (min-width: 768px) {
  form .input input + label {
    left: 2.1875rem;
  }
}
@supports (-ms-ime-align: auto) {
  form input[type="text"].in + label,
  form input[type="text"]:focus + label,
  form input[type="email"].in + label,
  form input[type="email"]:focus + label,
  form input[type="tel"].in + label,
  form input[type="tel"]:focus + label,
  form input[type="password"].in + label,
  form input[type="password"]:focus + label {
    -webkit-transform: translateY(-2rem) scale(1);
    transform: translateY(-2rem) scale(1);
  }
}
@media all and (-ms-high-contrast: none) {
  .button--text {
    min-width: 5rem;
  }
}
.tooltip {
  cursor: pointer;
  position: absolute;
}
.tooltip__btn {
  background-color: #ff8b00;
  border-radius: 50%;
  display: block;
  height: 1.125rem;
  margin: 0 auto;
  position: relative;
  width: 1.125rem;
}
.tooltip__btn i {
  color: white;
  display: block;
  font-size: 0.7rem;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.tooltip__content {
  background-color: #ff8b00;
  border-radius: 0.375rem;
  bottom: 100%;
  left: 50%;
  opacity: 0;
  padding: 1rem;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  transition: opacity 0.4s ease, -webkit-transform 0.4s ease;
  transition: opacity 0.4s ease, transform 0.4s ease;
  transition: opacity 0.4s ease, transform 0.4s ease,
    -webkit-transform 0.4s ease;
  width: 10.625rem;
}
.tooltip__content:after {
  border-color: #ff8b00 transparent transparent transparent;
  border-style: solid;
  border-width: 0.625rem 0.8125rem 0 0.8125rem;
  bottom: calc(-0.625rem + 1px);
  content: "";
  display: block;
  height: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 0;
}
.tooltip__content p {
  color: white;
  font-size: 0.6875rem;
  line-height: 0.875rem;
}
.tooltip:hover .tooltip__content {
  opacity: 1;
  -webkit-transform: translate(-50%, -1em);
  transform: translate(-50%, -1em);
}
.home .bg .bubble:first-child {
  border-radius: 50%;
  display: block;

  position: absolute;
 
  background: linear-gradient(135deg, #3d7398 0%, #1c3e56 100%);
  -webkit-animation: animateBubble 25s linear infinite normal;
  animation: animateBubble 25s linear infinite normal;

  left: -45em;
}
@media screen and (min-width: 768px) {
  .home .bg .bubble:first-child {

    left: -35.625em;
  }
}
@media screen and (min-width: 992px) {
  .home .bg .bubble:first-child {
    left: -25em;
  }
}
.home .bg .bubble:nth-child(2) {
  border-radius: 50%;
  display: block;

  position: absolute;

  background: linear-gradient(135deg, #3d7398 0%, #1c3e56 100%);
  animation: animateBubbleSml 10s linear infinite reverse;

  left: 16.875em;
  visibility: hidden;
}
@media screen and (min-width: 1199px) {
  .home .bg .bubble:nth-child(2) {
    visibility: visible;
  }
}
.home .bg .bubble:nth-child(3) {
  border-radius: 50%;
  display: block;

  position: absolute;

  background: #84cff0;
  -webkit-animation: animateBubbleSml 15s linear infinite normal;
  animation: animateBubbleSml 15s linear infinite normal;

  left: 26.25em;
}
.home .bg .bubble:nth-child(4) {
  border-radius: 50%;
  display: block;

  position: absolute;

  background: #84cff0;
  animation: animateBubble 30s linear infinite reverse;

  left: -20.625em;
}
@media screen and (min-width: 768px) {
  .home .bg .bubble:nth-child(4) {
   
    left: 1.875em;
  }
}
@media screen and (min-width: 992px) {
  .home .bg .bubble:nth-child(4) {
    left: 5.625em;
  }
}
.home .bg .bubble:nth-child(5) {
  border-radius: 50%;
  display: block;
 
  position: absolute;
 
  background: #84cff0;
  animation: animateBubbleSml 15s linear infinite reverse;
 
  left: 15.625em;
  visibility: hidden;
}
@media screen and (min-width: 768px) {
  .home .bg .bubble:nth-child(5) {
    visibility: visible;
  }
}
.home .bg .bubble:nth-child(6) {
  border-radius: 50%;
  display: block;

  position: absolute;

  background: linear-gradient(135deg, #3d7398 0%, #1c3e56 100%);
  -webkit-animation: animateBubble 30s linear infinite normal;
  animation: animateBubble 30s linear infinite normal;
  right: -35.625em;

}
@media screen and (min-width: 768px) {
  .home .bg .bubble:nth-child(6) {
   
  }
}
@media screen and (min-width: 992px) {
  .home .bg .bubble:nth-child(6) {
    right: -38.75em;
  
  }
}
.home .bg .bubble:nth-child(7) {
  border-radius: 50%;
  display: block;

  position: absolute;

  background: #84cff0;
  animation: animateBubble 30s linear infinite reverse;
  right: -10em;
 
}
@media screen and (min-width: 768px) {
  .home .bg .bubble:nth-child(7) {
   
  }
}
.home .inner {
  margin: 0 auto;
  max-width: 34.375em;
  text-align: center;
}
.home .signin-box p:not(.description) {
  color: #c1c7ce;
  padding: 1rem 0 1.5rem;
}
@media screen and (min-width: 768px) {
  .home .signin-box p:not(.description) {
    font-size: 1.25em;
    line-height: 1.625rem;
    padding: 2rem 0 3rem;
  }
}
.home .signin-box p.description {
  padding: 2rem 0 0;
}
.rooms .rooms-container {
  background-color: white;
  margin: 0 auto;
  max-width: 59.375em;
}
.rooms .rooms-container .rooms-heading {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid rgba(193, 199, 206, 0.4);
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0 0 0.75em;
  position: relative;
}
.rooms .rooms-container .rooms-heading h3 {
  white-space: nowrap;
}
.rooms .rooms-container .rooms-heading .key {
  position: relative;
}
.rooms .rooms-container .rooms-heading .key span {
  background-color: #0082f9;
  border-radius: 50%;
  height: 0.875em;
  left: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0.875em;
}
.rooms .rooms-container .rooms-heading .key p {
  color: #c1c7ce;
  font-size: 0.875em;
  padding-left: 1.5rem;
}
.rooms .rooms-container .rooms-list {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  justify-content: space-between;
  padding: 1.5em 0 0;
}
.rooms .rooms-container .rooms-list .room {
  width: 100%;
}
.rooms .rooms-container .rooms-list .room a {
  background-color: #84cff0;
  border: 0.75em solid white;
  box-shadow: 4px 4px 11px rgba(98, 98, 98, 0.12);
  border-radius: 1.625em;
  display: block;
  margin: 0 0 2rem;
  padding-top: 42.5%;
  position: relative;
  text-align: center;
  transition: background-color 0.4s ease;
}
.rooms .rooms-container .rooms-list .room a h4,
.rooms .rooms-container .rooms-list .room a .participants {
  position: absolute;
}
.rooms .rooms-container .rooms-list .room a h4 {
  color: white;
  left: 50%;
  padding: 0 0.5rem;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 2;
}
.rooms .rooms-container .rooms-list .room a h4 span {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: block;
}
.rooms .rooms-container .rooms-list .room a h4 span:not(.cta) {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
}
.rooms .rooms-container .rooms-list .room a h4 span.cta {
  bottom: -0.9rem;
  color: #84cff0;
  font-size: 0.8125em;
  left: 50%;
  opacity: 0;
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  transition: opacity 0.4s ease;
}
.rooms .rooms-container .rooms-list .room a .participants {
  background-color: #0082f9;
  border-radius: 50%;
  border: 0.1875em solid white;
  color: white;
  font-size: 0.875em;
  height: 2.375rem;
  line-height: 2.375em;
  right: -0.75em;
  text-align: center;
  top: -0.75em;
  transition: background-color 0.4s ease, color 0.4s ease;
  width: 2.375rem;
  z-index: 1;
}
.rooms .rooms-container .rooms-list .room a:hover {
  background-color: #0082f9;
}
.rooms .rooms-container .rooms-list .room a:hover h4 span:not(.cta) {
  -webkit-transform: translateY(-0.75rem);
  transform: translateY(-0.75rem);
}
.rooms .rooms-container .rooms-list .room a:hover h4 span.cta {
  opacity: 1;
  transition-delay: 0.1s;
}
.rooms .rooms-container .rooms-list .room a:hover .participants {
  background-color: white;
  color: #0082f9;
}

@media screen and (min-width: 768px) {
  .rooms .rooms-container .rooms-list .room {
    width: calc(50% - 1em);
  }
}
@media screen and (min-width: 992px) {
  .rooms .rooms-container .rooms-list .room {
    width: calc(33.3333% - 1em);
  }
}
@media screen and (min-width: 768px) {
  .rooms .rooms-container .rooms-list {
    padding: 3em 0 0;
  }
}
@media screen and (min-width: 768px) {
  .rooms .rooms-container {
    border-radius: 1.625em;
    box-shadow: 4px 4px 11px rgba(98, 98, 98, 0.12);
    padding: 2em 3em 1em;
  }
}
.patients {
  padding: 0 0 4.5em;
}
.patients ul.select-room {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 10;
}
.patients ul.select-room li {
  display: block;
}
.patients ul.select-room li a {
  display: block;
  color: #1c3e56;
  transition: color 0.2s ease;
}
.patients ul.select-room li a:hover {
  color: #84cff0;
}
.patients ul.select-room .label {
  cursor: pointer;
  transition: color 0.2s ease;
}
.patients ul.select-room .label i {
  color: #0082f9;
  display: inline-block;
  font-size: 0.7em;
  -webkit-transform: rotate(0deg) translateX(1px);
  transform: rotate(0deg) translateX(1px);
  transition: color 0.2s ease;
}
.patients ul.select-room .label:hover + .rooms-list {
  opacity: 1;
  pointer-events: auto;
}
.patients ul.select-room .rooms-list {
  background-color: #eef4fb;
  border-radius: 0.375em;
  box-shadow: 4px 4px 11px rgba(98, 98, 98, 0.12);
  display: block;
  left: 0;
  min-width: 9.375em;
  opacity: 0;
  padding: 1em 1.5em 1em 1em;
  pointer-events: none;
  position: absolute;
  top: 100%;
  transition: opacity 0.2s ease;
}
.patients ul.select-room .rooms-list li {
  font-size: 0.875em;
  font-weight: 400;
  white-space: nowrap;
}
.patients ul.select-room .rooms-list li:not(:last-child) {
  margin-bottom: 0.5rem;
}
.patients ul.select-room .rooms-list:hover {
  opacity: 1;
  pointer-events: auto;
}
.patients ul.select-room:hover .label {
  color: #84cff0;
}
.patients ul.select-room:hover .label i {
  color: #84cff0;
}
.patients .room-details {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 2em;
  position: relative;
  z-index: 1;
}
.patients .room-details h3 {
  color: #84cff0;
  font-weight: 400;
  padding-bottom: 1rem;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .patients .room-details h3 {
    padding-bottom: 0;
    width: auto;
  }
}
.patients .room-details ul.select-room li.label {
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .patients .room-details ul.select-room {
    margin-left: 1.5em;
  }
}
.patients .room-details .refresh {
  background: none;
  border: none;
  color: #1c3e56;
  margin: 0 0 0 3em;
  outline: 0;
  padding: 0;
  transition: color 0.4s ease;
}
.patients .room-details .refresh i {
  color: #0082f9;
  display: inline-block;
  -webkit-transform: translate(-2px, 1px) rotate(0deg);
  transform: translate(-2px, 1px) rotate(0deg);
}
.patients .room-details .refresh:hover {
  color: #84cff0;
}
.patients .room-details .refresh:hover i {
  -webkit-transform: translate(-2px, 1px) rotate(360deg);
  transform: translate(-2px, 1px) rotate(360deg);
  transition: -webkit-transform 0.8s ease 0.05s;
  transition: transform 0.8s ease 0.05s;
  transition: transform 0.8s ease 0.05s, -webkit-transform 0.8s ease 0.05s;
}
.patients .patients-list {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  justify-content: space-between;
}
.patients .patients-list .patient {
  background-color: white;
  border-radius: 1.625em;
  box-shadow: 4px 4px 11px rgba(98, 98, 98, 0.12);
  margin: 0 0 1.5em;
  width: 100%;
}
.patients .patients-list .patient p {
  font-size: 0.875em;
  line-height: 1.375rem;
}
.patients .patients-list .patient p:not(.label) {
  font-weight: 400;
}
.patients .patients-list .patient__header {
  background-color: rgba(238, 244, 251, 0.6);
  border-top-left-radius: 1.625em;
  border-top-right-radius: 1.625em;
  padding: 1.5em 6.5em 1.5em 1.5em;
  position: relative;
}
.patients .patients-list .patient__header p span {
  color: #1c3e56;
  font-weight: 400;
}
.patients .patients-list .patient__header .details p:not(:last-child) {
  margin-bottom: 0.5rem;
}
.patients .patients-list .patient__header .device {
  background-color: #c1c7ce;
  border-radius: 50%;
  display: block;
  height: 4.375em;
  position: absolute;
  right: 1em;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 4.375em;
}
.patients .patients-list .patient__header .device i {
  color: #1c3e56;
  display: block;
  font-size: 1.9em;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.patients .patients-list .patient__body {
  padding: 0.5em 1.5em 1em;
}
.patients .patients-list .patient__body .label {
  margin-bottom: 0.25rem;
}
.patients .patients-list .patient__footer {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  border-bottom-left-radius: 1.625em;
  border-bottom-right-radius: 1.625em;
  padding: 0 1.5em 1.5em;
}
.patients .patients-list .patient__footer button:not(:last-of-type) {
  margin: 0 0 0.5em;
}
@media screen and (min-width: 768px) {
  .patients .patients-list .patient__footer button:not(:last-of-type) {
    margin: 0 0.5em 0 0;
  }
}
.patients .patients-list .patient__footer ul.select-room {
  margin-left: auto;
  margin-top: 1.25em;
}
.patients .patients-list .patient__footer ul.select-room li {
  font-size: 0.875em;
  font-weight: 400;
}
.patients .patients-list .patient__footer ul.select-room .rooms-list {
  min-width: 6.5625em;
}
@media screen and (min-width: 768px) {
  .patients .patients-list .patient__footer ul.select-room {
    margin-top: 0;
  }
}
.patients .patients-list .patient.arrival-short .patient__header .device {
  background-color: #7aea77;
}
.patients .patients-list .patient.arrival-medium .patient__header .device {
  background-color: #f2b92d;
}
.patients .patients-list .patient.arrival-long .patient__header .device {
  background-color: #e94054;
}
@media screen and (min-width: 768px) {
  .patients .patients-list .patient {
    width: calc(50% - 1em);
  }
}
@media screen and (min-width: 1199px) {
  .patients .patients-list .patient {
    width: calc(33.3333% - 1em);
  }
}
.begin-consultation {
  padding: 9em 0 3em;
}
.begin-consultation .bg {
  display: none;
}
.begin-consultation .bg .bubble:first-child {
  border-radius: 50%;
  display: block;
  height: 51.25em;
  position: absolute;
  width: 51.25em;
  background: linear-gradient(135deg, #3d7398 0%, #1c3e56 100%);
  -webkit-animation: animateBubble 25s linear infinite normal;
  animation: animateBubble 25s linear infinite normal;
  bottom: -37.5em;
  left: -45em;
}
@media screen and (min-width: 768px) {
  .begin-consultation .bg .bubble:first-child {
    bottom: -34.375em;
    left: -35.625em;
  }
}
@media screen and (min-width: 992px) {
  .begin-consultation .bg .bubble:first-child {
    left: -25em;
  }
}
.begin-consultation .bg .bubble:nth-child(2) {
  border-radius: 50%;
  display: block;
  height: 51.25em;
  position: absolute;
  width: 51.25em;
  background: #84cff0;
  animation: animateBubble 30s linear infinite reverse;
  bottom: -49.375em;
  left: -20.625em;
}
@media screen and (min-width: 768px) {
  .begin-consultation .bg .bubble:nth-child(2) {
    bottom: -43.75em;
    left: 1.875em;
  }
}
@media screen and (min-width: 992px) {
  .begin-consultation .bg .bubble:nth-child(2) {
    left: -5.625em;
  }
}
.begin-consultation .bg .bubble:nth-child(3) {
  border-radius: 50%;
  display: block;
  height: 51.25em;
  position: absolute;
  width: 51.25em;
  background: linear-gradient(135deg, #3d7398 0%, #1c3e56 100%);
  animation: animateBubble 30s linear infinite reverse;
  bottom: -43.75em;
  display: none;
  right: -20.625em;
}
@media screen and (min-width: 992px) {
  .begin-consultation .bg .bubble:nth-child(3) {
    display: block;
  }
}
.begin-consultation .bg .bubble:nth-child(4) {
  border-radius: 50%;
  display: block;
  height: 51.25em;
  position: absolute;
  width: 51.25em;
  background: linear-gradient(135deg, #3d7398 0%, #1c3e56 100%);
  -webkit-animation: animateBubble 30s linear infinite normal;
  animation: animateBubble 30s linear infinite normal;
  display: none;
  right: -28.125em;
  top: -41.25em;
}
@media screen and (min-width: 992px) {
  .begin-consultation .bg .bubble:nth-child(4) {
    display: block;
  }
}
@media screen and (min-width: 768px) {
  .begin-consultation .bg {
    display: block;
  }
}
.begin-consultation .container {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  justify-content: space-between;
}
.begin-consultation .container .col {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .begin-consultation .container .col {
    width: calc(50% - 1.5em);
  }
}
@media screen and (min-width: 992px) {
  .begin-consultation .container .col {
    width: calc(50% - 6em);
  }
}

.begin-consultation .call-setup h2,
.begin-consultation .call-setup p {
  color: white;
}
.begin-consultation .call-setup .inner {
  background: #84cff0;
  border-radius: 1.625em;
  box-shadow: 4px 4px 11px rgba(98, 98, 98, 0.12);
  text-align: center;
}
.begin-consultation .call-setup .inner__body {
  padding: 3.5em 1.5em 1.5em;
  position: relative;
}
.begin-consultation .call-setup .inner__body h2 {
  padding-bottom: 1.25rem;
}
.begin-consultation .call-setup .inner__body span {
  background: #0082f9;
  border-radius: 50%;
  color: white;
  display: block;
  height: 4.125em;
  left: 50%;
  position: absolute;
  top: -2.0625em;
  text-align: center;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 4.125em;
}
.begin-consultation .call-setup .inner__body span i {
  display: block;
  font-size: 2.2em;
  line-height: 4.125rem;
}
@media screen and (min-width: 768px) {
  .begin-consultation .call-setup .inner__body {
    padding: 3.5em 3em 3em;
  }
}
.begin-consultation .call-setup .inner__cta .begin-test {
  background-color: #0082f9;
  border: none;
  border-bottom-left-radius: 1.625rem;
  border-bottom-right-radius: 1.625rem;
  color: white;
  display: block;
  font-size: 1.125em;
  font-weight: 300;
  margin: 0;
  outline: 0;
  padding: 1.5rem 0;
  transition: background-color 0.4s ease;
  width: 100%;
}
.begin-consultation .call-setup .inner__cta .begin-test .btn-inner {
  position: relative;
}
.begin-consultation .call-setup .inner__cta .begin-test .btn-inner .tooltip {
  right: -2em;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.begin-consultation .call-setup .inner__cta .begin-test:hover {
  background-color: #003f7a;
}
@media screen and (min-width: 768px) {
  .begin-consultation .call-setup .inner__cta .begin-test {
    font-size: 1.25em;
  }
}
.begin-consultation .setup-form {
  padding: 3em 0 0;
}
.begin-consultation .setup-form .intro {
  padding: 0 0 1.5em;
}
.begin-consultation .setup-form .intro h2 {
  color: #1c3e56;
  padding-bottom: 1rem;
}
.begin-consultation .setup-form .intro p {
  color: #c1c7ce;
}
.begin-consultation .setup-form form fieldset .input:not(:last-of-type) {
  margin-bottom: 1.5em;
}
/* .begin-consultation .setup-form form .submit-btn {
  box-shadow: 0 2px 6px rgba(34, 195, 30, 0.4);
  margin-top: 3em;
} */
@media screen and (min-width: 768px) {
  .begin-consultation .setup-form {
    padding: 0;
  }
}
@media screen and (min-width: 768px) {
  .begin-consultation {
    padding: 0;
  }
}
.test-call {
  padding: 7.5em 0 3em;
}
.test-call .bg .bubble:first-child {
  border-radius: 50%;
  display: block;
  height: 51.25em;
  position: absolute;
  width: 51.25em;
  background: linear-gradient(135deg, #3d7398 0%, #1c3e56 100%);
  -webkit-animation: animateBubble 25s linear infinite normal;
  animation: animateBubble 25s linear infinite normal;
  bottom: -37.5em;
  left: -45em;
}
@media screen and (min-width: 768px) {
  .test-call .bg .bubble:first-child {
    bottom: -34.375em;
    left: -35.625em;
  }
}
@media screen and (min-width: 992px) {
  .test-call .bg .bubble:first-child {
    left: -25em;
  }
}
.test-call .bg .bubble:nth-child(2) {
  border-radius: 50%;
  display: block;
  height: 2.8125em;
  position: absolute;
  width: 2.8125em;
  background: linear-gradient(135deg, #3d7398 0%, #1c3e56 100%);
  animation: animateBubbleSml 10s linear infinite reverse;
  bottom: 12.5em;
  left: 16.875em;
  visibility: hidden;
}
@media screen and (min-width: 1199px) {
  .test-call .bg .bubble:nth-child(2) {
    visibility: visible;
  }
}
.test-call .bg .bubble:nth-child(3) {
  border-radius: 50%;
  display: block;
  height: 3.125em;
  position: absolute;
  width: 3.125em;
  background: #84cff0;
  -webkit-animation: animateBubbleSml 15s linear infinite normal;
  animation: animateBubbleSml 15s linear infinite normal;
  bottom: 6.25em;
  left: 26.25em;
}
.test-call .bg .bubble:nth-child(4) {
  border-radius: 50%;
  display: block;
  height: 51.25em;
  position: absolute;
  width: 51.25em;
  background: #84cff0;
  animation: animateBubble 30s linear infinite reverse;
  bottom: -49.375em;
  left: -20.625em;
}
@media screen and (min-width: 768px) {
  .test-call .bg .bubble:nth-child(4) {
    bottom: -46.875em;
    left: 1.875em;
  }
}
@media screen and (min-width: 992px) {
  .test-call .bg .bubble:nth-child(4) {
    left: 5.625em;
  }
}
.test-call .bg .bubble:nth-child(5) {
  border-radius: 50%;
  display: block;
  height: 1.375em;
  position: absolute;
  width: 1.375em;
  background: #84cff0;
  animation: animateBubbleSml 15s linear infinite reverse;
  bottom: 2.5em;
  left: 15.625em;
  visibility: hidden;
}
@media screen and (min-width: 768px) {
  .test-call .bg .bubble:nth-child(5) {
    visibility: visible;
  }
}
.test-call .bg .bubble:nth-child(6) {
  border-radius: 50%;
  display: block;
  height: 51.25em;
  position: absolute;
  width: 51.25em;
  background: linear-gradient(135deg, #3d7398 0%, #1c3e56 100%);
  -webkit-animation: animateBubble 30s linear infinite normal;
  animation: animateBubble 30s linear infinite normal;
  bottom: -43.75em;
  right: -19.375em;
}
.test-call .bg .bubble:nth-child(7) {
  border-radius: 50%;
  display: block;
  height: 1.375em;
  position: absolute;
  width: 1.375em;
  background: #84cff0;
  animation: animateBubble 30s linear infinite reverse;
  bottom: 3.125em;
  right: 21.25em;
}
.test-call .bg .bubble:nth-child(8) {
  border-radius: 50%;
  display: block;
  height: 3.125em;
  position: absolute;
  width: 3.125em;
  background: linear-gradient(135deg, #3d7398 0%, #1c3e56 100%);
  animation: animateBubble 30s linear infinite reverse;
  bottom: 8.125em;
  right: 7.5em;
}
.test-call .bg .bubble:nth-child(3),
.test-call .bg .bubble:nth-child(6),
.test-call .bg .bubble:nth-child(7),
.test-call .bg .bubble:nth-child(8) {
  display: none;
}
@media screen and (min-width: 768px) {
  .test-call .bg .bubble:nth-child(3),
  .test-call .bg .bubble:nth-child(6),
  .test-call .bg .bubble:nth-child(7),
  .test-call .bg .bubble:nth-child(8) {
    display: block;
  }
}
@media screen and (min-width: 1440px) {
  .test-call .bg .bubble:nth-child(7) {
    display: none;
  }
}
.test-call .container .inner {
  margin: 0 auto;
  max-width: 38.75em;
}
.test-call .camera-preview {
  margin: 0 auto;
  max-width: 17.1875em;
  padding: 0 0 3em;
}
.test-call .camera-preview .preview-wrapper {
  background: black;
  border-radius: 0.375em;
  overflow: hidden;
  padding-top: 75%;
  position: relative;
}
.test-call .setup-list {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  justify-content: space-between;
}
.test-call .setup-list .item {
  text-align: center;
  padding: 0 0 2em;
  position: relative;
  width: calc(33.3333% - 0.5em);
}
.test-call .setup-list .item .description {
  font-size: 0.8125em;
  font-weight: 400;
  line-height: 1.125rem;
}
@media screen and (min-width: 768px) {
  .test-call .setup-list .item .description {
    font-size: 1em;
    line-height: 1.375rem;
  }
}
.test-call .setup-list .item .icon {
  background-color: #eef4fb;
  border: 1px solid #84cff0;
  border-radius: 50%;
  display: block;
  height: 3.75em;
  margin: 0 auto 0.75em;
  position: relative;
  width: 3.75em;
}
.test-call .setup-list .item .icon .status {
  border-radius: 50%;
  border: 2px solid white;
  height: 1.5625em;
  position: absolute;
  right: -0.3125em;
  top: -0.3125em;
  width: 1.5625em;
}
.test-call .setup-list .item .icon .status i {
  color: white;
  font-size: 1.1em;
}
.test-call .setup-list .item .icon .status i.icon-cross {
  font-size: 1.2em;
}
@media screen and (min-width: 768px) {
  .test-call .setup-list .item .icon .status {
    right: 0;
    top: 0;
  }
}
.test-call .setup-list .item .icon i {
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.test-call .setup-list .item .icon .type {
  color: #84cff0;
  font-size: 1.75em;
}
@media screen and (min-width: 768px) {
  .test-call .setup-list .item .icon .type {
    font-size: 2.5em;
  }
}
@media screen and (min-width: 768px) {
  .test-call .setup-list .item .icon {
    height: 5.625em;
    width: 5.625em;
  }
}
.test-call .setup-list .item .tooltip {
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.test-call .setup-list .item--fail .description {
  color: #e94054;
}
.test-call .setup-list .item--fail .icon {
  background-color: #e94054;
  border-color: #e94054;
}
.test-call .setup-list .item--fail .icon .status {
  background-color: #e94054;
}
.test-call .setup-list .item--fail .icon .type {
  color: white;
}
.test-call .setup-list .item--success .icon {
  background-color: #7aea77;
  border-color: #7aea77;
}
.test-call .setup-list .item--success .icon .status {
  background-color: #7aea77;
}
.test-call .setup-list .item--success .icon .type {
  color: white;
}
@media screen and (min-width: 768px) {
  .test-call .setup-list .item {
    width: calc(33.3333% - 1.5em);
  }
}
@media screen and (min-width: 768px) {
  .test-call {
    padding: 0;
  }
}
.call-screen section .container {
  height: calc(100vh - 10em);
  margin-top: 5em;
  max-height: 43.75em;
}

@media screen and (min-width: 768px) and (min-height: 900px) {
  .call-screen section .container {
    height: calc(100vh - 1.5em);
    width: calc(100vw - 2em);
    margin-top: 0;
  }
}
.call-screen .screen-wrapper {
  height: 100%;
  margin: 0 auto;
  /* max-width: 72.5em; */
  position: relative;
  width: 100%;
}
.call-screen .screen-wrapper .visitor-screen {
  /* background: black;
  border-radius: 0.25rem; */
  height: 100%;
  left: 0;
  overflow: hidden;
  /* position: absolute; */
  top: 0;
  width: 100%;
  z-index: 0;
}
.call-screen .screen-wrapper .user-screen {
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0.25rem;
  height: 4.25em;
  overflow: hidden;
  position: absolute;
  right: 0.4em;
  top: auto;
  bottom: 0.4em;
  width: 7.5em;
}
@media screen and (min-width: 768px) {
  .call-screen .screen-wrapper .user-screen {
    height: 8.5em;
    right: 0.8em;
    top: auto;
    bottom: 0.8em;
    width: 15em;
  }
}
@media screen and (min-width: 992px) {
  .call-screen .screen-wrapper .user-screen {
    bottom: 0.8em;
    height: 8.5em;
    top: auto;
    width: 15em;
  }
}

@media screen and (orientation: portrait) {
  .call-screen .screen-wrapper .user-screen {
    bottom: 0.8em;
    height: 10em;
    top: auto;
    width: 6em;
  }
}

.call-screen .screen-wrapper .screen-controls {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  bottom: 0.8em;
  justify-content: space-between;
  left: 10%;
  right: 10%;
  position: absolute;
  /* -webkit-transform: translateX(-50%); */
  /* transform: translateX(-50%); */
  width: 10.625em;
  z-index: 2;
}
.call-screen .screen-wrapper .screen-controls .control-btn {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 0.25rem;
  color: #1c3e56;
  display: block;
  font-size: 0.6875em;
  height: 4.0625rem;
  justify-content: center;
  margin: 0 0 0.3rem;
  outline: 0;
  padding: 0;
  transition: background-color 0.4s ease;
  width: calc(50% - 0.2em);
}
.call-screen .screen-wrapper .screen-controls .control-btn span {
  display: block;
  white-space: nowrap;
}
.call-screen .screen-wrapper .screen-controls .control-btn:hover {
  background-color: #eef4fb;
}
.call-screen .screen-wrapper .screen-controls .control-btn--disconnect {
  background: #e94054;
  color: white;
}
.call-screen .screen-wrapper .screen-controls .control-btn--disconnect:hover {
  background-color: #dd1931;
}
@media screen and (min-width: 768px) {
  .call-screen .screen-wrapper .screen-controls .control-btn {
    margin: 0 0.2rem;
    width: 5rem;
  }
}
@media screen and (min-width: 768px) {
  .call-screen .screen-wrapper .screen-controls {
    width: auto;
  }
}
.call-screen .screen-wrapper .message {
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  font-size: 1.50em;
}
@media screen and (min-width: 768px) {
  .call-screen .screen-wrapper .message {
    font-size: 2.25em;
    line-height: 2.8125rem;
  }
}
.call-screen .bg .bubble:first-child {
  border-radius: 50%;
  display: block;
  height: 51.25em;
  position: absolute;
  width: 51.25em;
  background: linear-gradient(135deg, #3d7398 0%, #1c3e56 100%);
  -webkit-animation: animateBubble 25s linear infinite normal;
  animation: animateBubble 25s linear infinite normal;
  bottom: -37.5em;
  left: -45em;
}
@media screen and (min-width: 768px) {
  .call-screen .bg .bubble:first-child {
    bottom: -34.375em;
    left: -35.625em;
  }
}
@media screen and (min-width: 992px) {
  .call-screen .bg .bubble:first-child {
    left: -25em;
  }
}
.call-screen .bg .bubble:nth-child(2) {
  border-radius: 50%;
  display: block;
  height: 2.8125em;
  position: absolute;
  width: 2.8125em;
  background: linear-gradient(135deg, #3d7398 0%, #1c3e56 100%);
  animation: animateBubbleSml 10s linear infinite reverse;
  bottom: 12.5em;
  left: 16.875em;
  visibility: hidden;
}
@media screen and (min-width: 1199px) {
  .call-screen .bg .bubble:nth-child(2) {
    visibility: visible;
  }
}
.call-screen .bg .bubble:nth-child(3) {
  border-radius: 50%;
  display: block;
  height: 3.125em;
  position: absolute;
  width: 3.125em;
  background: #84cff0;
  -webkit-animation: animateBubbleSml 15s linear infinite normal;
  animation: animateBubbleSml 15s linear infinite normal;
  bottom: 6.25em;
  left: 26.25em;
}
.call-screen .bg .bubble:nth-child(4) {
  border-radius: 50%;
  display: block;
  height: 51.25em;
  position: absolute;
  width: 51.25em;
  background: #84cff0;
  animation: animateBubble 30s linear infinite reverse;
  bottom: -49.375em;
  left: -20.625em;
}
@media screen and (min-width: 768px) {
  .call-screen .bg .bubble:nth-child(4) {
    bottom: -46.875em;
    left: 1.875em;
  }
}
@media screen and (min-width: 992px) {
  .call-screen .bg .bubble:nth-child(4) {
    left: 5.625em;
  }
}
.call-screen .bg .bubble:nth-child(5) {
  border-radius: 50%;
  display: block;
  height: 1.375em;
  position: absolute;
  width: 1.375em;
  background: #84cff0;
  animation: animateBubbleSml 15s linear infinite reverse;
  bottom: 2.5em;
  left: 15.625em;
  visibility: hidden;
}
@media screen and (min-width: 768px) {
  .call-screen .bg .bubble:nth-child(5) {
    visibility: visible;
  }
}
.call-screen .bg .bubble:nth-child(6) {
  border-radius: 50%;
  display: block;
  height: 51.25em;
  position: absolute;
  width: 51.25em;
  background: linear-gradient(135deg, #3d7398 0%, #1c3e56 100%);
  -webkit-animation: animateBubble 30s linear infinite normal;
  animation: animateBubble 30s linear infinite normal;
  bottom: -43.75em;
  right: -19.375em;
}
.call-screen .bg .bubble:nth-child(7) {
  border-radius: 50%;
  display: block;
  height: 1.375em;
  position: absolute;
  width: 1.375em;
  background: #84cff0;
  animation: animateBubble 30s linear infinite reverse;
  bottom: 3.125em;
  right: 21.25em;
}
.call-screen .bg .bubble:nth-child(8) {
  border-radius: 50%;
  display: block;
  height: 3.125em;
  position: absolute;
  width: 3.125em;
  background: linear-gradient(135deg, #3d7398 0%, #1c3e56 100%);
  animation: animateBubble 30s linear infinite reverse;
  bottom: 8.125em;
  right: 7.5em;
}
.call-screen .bg .bubble:nth-child(3),
.call-screen .bg .bubble:nth-child(6),
.call-screen .bg .bubble:nth-child(7),
.call-screen .bg .bubble:nth-child(8) {
  display: none;
}
@media screen and (min-width: 768px) {
  .call-screen .bg .bubble:nth-child(3),
  .call-screen .bg .bubble:nth-child(6),
  .call-screen .bg .bubble:nth-child(7),
  .call-screen .bg .bubble:nth-child(8) {
    display: block;
  }
}
header {
  left: 0;
  overflow: hidden;
  padding: 1.5em 0 0;
  /* position: absolute; */
  top: 0;
  width: 100%;
  z-index: 2;
}
header .container {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  justify-content: space-between;
}
header .logo {
  width: 15em;
}
header .menu-toggle {
  background: none;
  border: none;
  display: inline-block;
  margin: 0;
  outline: 0;
  padding: 0;
  position: absolute;
  right: 1em;
  top: 1.1em;
  z-index: 999;
}
header .menu-toggle i {
  color: #84cff0;
  display: inline-block;
  font-size: 2.5em;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: color 0.8s cubic-bezier(0.43, 0.195, 0.02, 1),
    -webkit-transform 0.8s cubic-bezier(0.43, 0.195, 0.02, 1);
  transition: color 0.8s cubic-bezier(0.43, 0.195, 0.02, 1),
    transform 0.8s cubic-bezier(0.43, 0.195, 0.02, 1);
  transition: color 0.8s cubic-bezier(0.43, 0.195, 0.02, 1),
    transform 0.8s cubic-bezier(0.43, 0.195, 0.02, 1),
    -webkit-transform 0.8s cubic-bezier(0.43, 0.195, 0.02, 1);
}
header .menu-toggle.in i {
  color: #1c3e56;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  transition-delay: 0.2s;
}
@media screen and (min-width: 992px) {
  header .menu-toggle {
    display: none;
  }
}
header .menu-wrapper {
  background: #eef4fb;
  height: 100%;
  left: 0;
  padding: 0 1em;
  position: fixed;
  top: 0;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  transition: -webkit-transform 0.8s cubic-bezier(0.43, 0.195, 0.02, 1);
  transition: transform 0.8s cubic-bezier(0.43, 0.195, 0.02, 1);
  transition: transform 0.8s cubic-bezier(0.43, 0.195, 0.02, 1),
    -webkit-transform 0.8s cubic-bezier(0.43, 0.195, 0.02, 1);
  width: 100%;
  z-index: 998;
}
header .menu-wrapper .profile {
  display: inline-block;
  padding: 0.9em 0 0;
}
header .menu-wrapper .profile a {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (min-width: 992px) {
  header .menu-wrapper .profile a:hover .profile__name {
    color: white;
  }
}
header .menu-wrapper .profile__image {
  border-radius: 50%;
  height: auto;
  width: 2.8125em;
}
@media screen and (min-width: 768px) {
  header .menu-wrapper .profile__image {
    width: 1.875em;
  }
}
header .menu-wrapper .profile__name {
  color: #1c3e56;
  font-size: 0.875em;
  font-weight: 400;
  padding-left: 0.5rem;
  transition: color 0.2s ease;
}
@media screen and (min-width: 768px) {
  header .menu-wrapper .profile__name {
    color: #84cff0;
    font-size: 0.6875em;
    font-weight: 300;
    line-height: 0.9375rem;
  }
}
@media screen and (min-width: 992px) {
  header .menu-wrapper .profile {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    justify-content: flex-end;
    padding: 0 0 1.5em;
    width: 100%;
  }
}
@media screen and (max-width: 991px) {
  header .menu-wrapper nav {
    left: 0;
    padding: 0 1em;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
  }
}
header .menu-wrapper .menu {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
header .menu-wrapper .menu li {
  background-color: white;
  border-radius: 0.8125em;
  box-shadow: 4px 4px 11px rgba(98, 98, 98, 0.12);
  margin-bottom: 1em;
  text-align: center;
  width: calc(50% - 0.5em);
}
header .menu-wrapper .menu li a {
  color: #1c3e56;
  display: block;
  font-size: 1.125em;
  font-weight: 400;
  padding: 1.5rem 0;
  transition: color 0.4s ease;
}
header .menu-wrapper .menu li a:last-child {
  padding-right: 0;
}
header .menu-wrapper .menu li a img,
header .menu-wrapper .menu li a span {
  display: block;
}
header .menu-wrapper .menu li a img {
  margin: 0 auto;
  min-height: 53px;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
  width: 45px;
}
@media screen and (min-width: 992px) {
  header .menu-wrapper .menu li a img {
    min-height: auto;
    width: auto;
  }
}
header .menu-wrapper .menu li a span {
  padding-top: 0.25rem;
}
header .menu-wrapper .menu li a:hover {
  color: #84cff0;
}
header .menu-wrapper .menu li a:hover img {
  -webkit-transform: translateY(-0.25rem);
  transform: translateY(-0.25rem);
}
@media screen and (min-width: 992px) {
  header .menu-wrapper .menu li a {
    color: white;
    font-size: 0.875em;
    font-weight: 300;
    padding: 0 1rem;
  }
  header .menu-wrapper .menu li a:last-child {
    padding-right: 0;
  }
}
@media screen and (min-width: 768px) {
  header .menu-wrapper .menu li {
    width: calc(25% - 0.5em);
  }
}
@media screen and (min-width: 992px) {
  header .menu-wrapper .menu li {
    background: none;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 0;
    width: auto;
  }
}
@media screen and (min-width: 992px) {
  header .menu-wrapper .menu {
    width: 33.125em;
  }
}
header .menu-wrapper.in {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
@media screen and (min-width: 992px) {
  header .menu-wrapper {
    background: none;
    height: auto;
    padding: 0;
    position: relative;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    transition: none;
    width: auto;
  }
}
@media screen and (min-width: 992px) {
  header.no-nav {
    padding: 1.5em 0 0;
  }
}
@media screen and (min-width: 992px) {
  header {
    padding: 1em 0 0;
  }
}
footer {
  padding: 1.5em 0;
  text-align: center;
  width: 100%;
  z-index: 2;
}
footer p {
  color: #84cff0;
  font-size: 0.75em;
}
@media screen and (min-width: 768px) {
  footer {
    bottom: 0.3em;
    left: 0;
    padding: 0;
    text-align: center;
  }
}
.scroll footer {
  bottom: 0;
  padding: 1.5em 0;
  position: relative;
}
/* 
#local-render {
  height: 100%;
  width: 100%;
} */

.video-element,
.local-render {
  width: 100%;
}


.center {
    margin: auto;
    width: 100%;
    text-align: center;
    padding: 1px;
  }

.video-wrapper {
  width: 90vw;
  height: 85vh;
}