#bannerBar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 5px;
  background-color: #F5F5F5;
  text-align: center;
  vertical-align: middle;
  color: #000000;
  z-index: 5000;
  display:flex;
  align-items: center;
  justify-content: center;
}

#bannerBar a{
  display: inline-block;
  width: 115px;
  height: 25px;
  background: #5B5FC7;
  text-align: center;
  border-radius: 5px;
  color: black;
  font-size: medium ;
}

#JoinCall{
  background: #5B5FC7 !important;
  color: #F5F5F5 !important;
}

#DismissCall{
  background: #FFFFFF !important; 
  color: #000000 !important;
}

.bannerBarContent{
  font-size:large ;
  color: black;
  text-align: center;
  font-weight: bold;
}

.clear:after {
  clear: both;
  display: table;
  content: "";
}